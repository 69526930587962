import { useEffect, useState } from "react";
import { FormHelperText } from "@mui/material";
import { ICreativeRequirement } from "types";

import ContentFileReader from "components/ContentFileReader";
import { faImage } from "@fortawesome/pro-light-svg-icons";

import AssetInfo from "./components/AssetInfo";
import CardTop from "./components/CardTop";
import AssetPreview from "./components/AssetPreview";
import Wrapper from "./components/Wrapper";

const TOO_SMALL_IMAGE_MESSAGE =
  "This image is too small and may appear low quality in the newsletter.";
const IMAGE_SMALLER_THAN_REQUIRED_MESSAGE =
  "Your image resolution is too low. It will be resized to meet the publisher's requirements.";
const IMAGE_UPLOAD_PLACEHOLDER_TEXT = "Upload an image for your sponsorship";

const MIN_IMAGE_DIMENSION_PERCENTAGE = 60;

const areDimensionsBelowMinPercentage = (
  fileSize: [number, number] | null,
  width?: number,
  height?: number
) => {
  if (!fileSize || !width || !height) return false;

  return (
    Math.round((fileSize[0] / width) * 100) < MIN_IMAGE_DIMENSION_PERCENTAGE ||
    Math.round((fileSize[1] / height) * 100) < MIN_IMAGE_DIMENSION_PERCENTAGE
  );
};

export default function CreativeItemImageField({
  creativeItem,
  onImageChange,
  error,
}: {
  creativeItem: ICreativeRequirement;
  onImageChange: (file: File | null) => void;
  error?: string;
}) {
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [newFilename, setNewFilename] = useState<string | undefined>();
  const [fileSize, setFileSize] = useState<[number, number] | null>(null);

  useEffect(() => {
    setImgFile(null);
  }, [creativeItem]);

  const { name, uuid, width, height, value: imgSrcUrl } = creativeItem;

  const isTooSmall = areDimensionsBelowMinPercentage(fileSize, width, height);

  const isSmallerThanRequired =
    !!fileSize &&
    !!width &&
    !!height &&
    (fileSize[0] < width || fileSize[1] < height);

  return !width || !height ? null : (
    <>
      <CardTop uuid={uuid} name={name} width={width} height={height} />
      <Wrapper error={!!error} warning={isTooSmall || isSmallerThanRequired}>
        <AssetPreview imgSrcUrl={imgSrcUrl} imgFile={imgFile} icon={faImage} />
        <AssetInfo
          newFilename={newFilename}
          srcUrl={imgSrcUrl as string | null}
          placeholder={IMAGE_UPLOAD_PLACEHOLDER_TEXT}
          extension="PNG, JPG or JPEG"
        />
        <div style={{ marginLeft: "10px" }}>
          <ContentFileReader
            name={uuid}
            onChange={(file) => {
              if (!!file) {
                setNewFilename(file.name);
                setImgFile(file);
                onImageChange(file);
              }
            }}
            onImageLoad={(fileSize: [number, number] | null) => {
              setFileSize(fileSize);
            }}
            caption={!!imgSrcUrl || !!imgFile ? "Change" : "Browse"}
            acceptedFileTypes=".png,.jpg,.jpeg"
          />
        </div>
      </Wrapper>

      {!!error && <FormHelperText error={true}>{error}</FormHelperText>}
      {(isTooSmall || isSmallerThanRequired) && (
        <FormHelperText sx={{ color: "#DE5D06" }}>
          {isTooSmall
            ? TOO_SMALL_IMAGE_MESSAGE
            : IMAGE_SMALLER_THAN_REQUIRED_MESSAGE}
        </FormHelperText>
      )}
    </>
  );
}

import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";

type AssetPreviewProps = {
  imgSrcUrl?: string | Blob;
  imgFile?: File | null;
  icon: IconDefinition;
};
export default function AssetPreview({
  imgSrcUrl,
  imgFile,
  icon,
}: AssetPreviewProps) {
  return (
    <Box
      sx={{
        width: 60,
        height: 60,
        borderRadius: 1,
        bgcolor: imgSrcUrl || imgFile ? "transparent" : "#f9fafc",
      }}
      display="flex"
      alignItems="center"
      justifyContent="space-around"
    >
      {imgSrcUrl || imgFile ? (
        <img
          style={{ maxWidth: "60px", maxHeight: "60px" }}
          src={
            imgFile ? URL.createObjectURL(imgFile as File) : String(imgSrcUrl)
          }
          alt="preview"
        />
      ) : (
        <FontAwesomeIcon icon={icon} />
      )}
    </Box>
  );
}

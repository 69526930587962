import React, { ChangeEvent } from "react";

import { ICreativeRequirement } from "types";
import CreativeItemImageField from "./CreativeItemImageField";
import CreativeItemHTMLField from "./CreativeItemHTMLField";
import { FormikErrors, FormikValues } from "formik";
import CreativeItemLinkField from "./CreativeItemLinkField";
import AITextField, { Suggestion } from "./AICreativeItemTextField";

export default function CreativeItemField({
  creativeItem,
  value,
  onChange,
  onBlur,
  setFieldValue,
  error,
  warning,
  validating,
  isValid,
  onValidateClick,
  options,
  isLoading,
  setFieldTouched,
}: {
  creativeItem: ICreativeRequirement;
  value: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  error?: string;
  warning?: string;
  validating?: boolean;
  isLoading?: boolean;
  isValid?: boolean | undefined;
  onValidateClick: () => void;
  options: Suggestion[];
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
}) {
  switch (creativeItem.creative_type) {
    case "image": {
      return (
        <CreativeItemImageField
          creativeItem={creativeItem}
          onImageChange={(file: File | null) =>
            setFieldValue(creativeItem.uuid, file)
          }
          error={error}
        />
      );
    }

    case "html": {
      return (
        <CreativeItemHTMLField
          creativeItem={creativeItem}
          error={error}
          onFileChange={(file: File | null) =>
            setFieldValue(creativeItem.uuid, file)
          }
        />
      );
    }

    case "link": {
      return (
        <CreativeItemLinkField
          creativeItem={creativeItem}
          // TODO: common helper for initializing undefined values
          value={value === undefined ? "" : value}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          warning={warning}
          validating={validating}
          isValid={isValid}
          onValidateClick={onValidateClick}
        />
      );
    }

    case "text": {
      return (
        <AITextField
          creativeItem={creativeItem}
          value={value === undefined ? "" : value}
          onChange={(string: string | null) => {
            setFieldValue(creativeItem.uuid, string);
            setFieldTouched(creativeItem.uuid, true, false);
          }}
          onBlur={onBlur}
          error={error}
          options={
            options.find((elem) => elem.uuid === creativeItem.uuid)?.options ||
            []
          }
          isLoading={isLoading}
        />
      );
    }

    default: {
      return null;
    }
  }
}

import React from "react";
import cls from "classnames";
import SignInputError from "components/SignInputError";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

type SignUpFieldLabelPosition = "top" | "inside";

interface SignUpFieldProps {
  name: string;
  value: string;
  type?: React.InputHTMLAttributes<unknown>["type"];
  caption: string;
  errorMessage?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent) => void;
  className?: string;
  showPassword?: boolean;
  onEndIconClick?: () => void;
  labelPosition?: SignUpFieldLabelPosition;
  labelText?: string;
}

export default function SignUpField({
  name,
  value,
  type,
  caption,
  errorMessage,
  onChange,
  onBlur,
  className,
  showPassword,
  onEndIconClick,
  labelPosition = "inside",
  labelText,
}: SignUpFieldProps) {
  return (
    <div
      className={cls(
        { floater: labelPosition === "inside" },
        className ?? "form-group",
        "signup-input-group"
      )}
    >
      {labelPosition === "top" && (
        <label className="input-caption" htmlFor={name}>
          {labelText || caption}
        </label>
      )}
      <input
        className={cls("form-input", {
          "form-input__outer-label": labelPosition !== "inside",
          "parsley-error": !!errorMessage,
        })}
        id={name}
        name={name}
        type={type ?? "text"}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        autoComplete="off"
        placeholder={caption}
      />
      {labelPosition === "inside" && <label htmlFor={name}>{caption}</label>}
      {name === "password" && (
        <IconButton
          onClick={onEndIconClick}
          sx={{ position: "absolute", top: 8, right: 4 }}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )}
      <SignInputError errorMessage={errorMessage} />
    </div>
  );
}

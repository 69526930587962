import React, { useState, useEffect, useMemo, useRef, Fragment } from "react";
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputLabel,
  TextField,
  Box,
  FormControlLabel,
  Typography,
  Link,
  Tooltip,
  Switch,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import axios from "axios";
import PayoutCalculator from "../components/payoutCalculator/PayoutCalculator";
import CreativeRequirements, { AssetError } from "./CreativeRequirements";
import { IPlacement } from "types";
import PlacementExample from "./PlacementExample";
import noop from "lodash/noop";
import { HelpOutline } from "@mui/icons-material";
import SmartPricingModal from "./SmartPricingModal";

export const PUBLISHED_PRICE_MIN = 25;
export const SMART_PRICE_SEED_PC = 0.6;

const locations = [
  {
    value: "top",
    label: "Top",
  },
  {
    value: "middle",
    label: "Middle",
  },
  {
    value: "bottom",
    label: "Bottom",
  },
  {
    value: "other",
    label: "Other",
  },
];

type Props = {
  prefix: string;
  values: IPlacement;
  touched: { [key: string]: any };
  errors: { [key: string]: any };
  index: number;
  isCanRemoved: boolean;
  isDeleting: boolean;
  exampleUrl: string;
  siteSlug: string;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: SelectChangeEvent | React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
  handleRemove: (index: number) => void;
};

function preFillSmartPrice(publishedPrice: number, smartPrice: number = 0) {
  if (smartPrice > 0) {
    return smartPrice;
  }

  return Math.max(
    Math.ceil(publishedPrice * SMART_PRICE_SEED_PC),
    PUBLISHED_PRICE_MIN
  );
}

export default function Placement({
  prefix,
  values,
  touched,
  errors,
  index,
  isCanRemoved,
  isDeleting,
  exampleUrl,
  siteSlug,
  setFieldValue,
  handleChange,
  handleBlur,
  handleRemove,
}: Props) {
  const [highlightPlacement, setHighlightPlacement] = useState("");
  useEffect(() => {
    if (values.id && siteSlug && exampleUrl)
      axios({
        method: "get",
        url: `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/sponsored_email/highlight_placement?id=${values.id}`,
      })
        .then((response) => {
          setHighlightPlacement(response.data.placement_screenshot);
        })
        .catch(noop);
  }, [values.id, siteSlug, exampleUrl]);

  const smartPricingDisable = useMemo(
    () => values["published_price"] <= PUBLISHED_PRICE_MIN,
    [values]
  );

  const [modalOpen, setModalOpen] = useState(false);
  const smartPricingToggle = (checked = false) => {
    const newValue = buttonRef.current?.value;
    if (newValue && values["smart_pricing_lowest_price"] === 0) {
      const initialSmartPriceValue = preFillSmartPrice(
        values["published_price"],
        values["smart_pricing_lowest_price"]
      );

      setFieldValue(
        `${prefix}.smart_pricing_lowest_price`,
        initialSmartPriceValue
      );
    }

    if (newValue === "true") {
      setFieldValue(`${prefix}.smart_pricing_enabled`, false);
    } else if (newValue === "false") {
      if (checked) {
        setFieldValue(`${prefix}.smart_pricing_enabled`, true);
      } else {
        setModalOpen(true);
      }
    }
  };

  const handleClose = (event: any, reason: any) => {
    if (reason === "backdropClick") {
      return;
    }
    setModalOpen(false);
  };

  const buttonRef = useRef<HTMLInputElement>(null);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClick = (event: any) => {
    event.preventDefault();
    if (event.nativeEvent.pointerType === "mouse") {
      window.open(
        "https://www.paved.com/help/articles/8124581-turn-on-smart-pricing",
        "_blank",
        "noopener,noreferrer"
      );
    } else if (event.nativeEvent.pointerType === "touch") {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const handleTooltipOpen = (event: any) => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Card>
      <SmartPricingModal
        open={modalOpen}
        handleClose={handleClose}
        buttonRef={buttonRef}
        smartPricingToggle={smartPricingToggle}
        buttonDisabled={smartPricingDisable}
      />
      <CardHeader
        title={values.name}
        titleTypographyProps={{ variant: "h6" }}
        action={
          isCanRemoved ? (
            <IconButton
              color="primary"
              disabled={isDeleting}
              onClick={() => handleRemove(index)}
            >
              {isDeleting ? (
                <CircularProgress size={20} />
              ) : (
                <DeleteForeverOutlinedIcon />
              )}
            </IconButton>
          ) : null
        }
      />
      <CardContent>
        <Grid container spacing={4} flexDirection="column">
          <Grid item>
            <InputLabel htmlFor={`${prefix}.name`}>Placement Name</InputLabel>
            <TextField
              id={`${prefix}.name`}
              name={`${prefix}.name`}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              data-error-element={Boolean(errors.name)}
              helperText={
                touched.name && Boolean(errors.name) ? errors.name : ""
              }
            />
            <FormHelperText>
              Enter a name for this placement e.g. "Featured Placement".
            </FormHelperText>
          </Grid>
          <Grid item>
            <InputLabel htmlFor={`${prefix}.placement_location`}>
              Placement Location
            </InputLabel>
            <Grid container spacing={1}>
              {locations.map((location) => (
                <Grid item key={location.value}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setFieldValue(
                        `${prefix}.placement_location`,
                        location.value
                      )
                    }
                    sx={{
                      color:
                        values.placement_location === location.value
                          ? "primary"
                          : "#656d77",
                      borderColor:
                        values.placement_location === location.value
                          ? "primary.main"
                          : "#dadcde",
                    }}
                  >
                    {location.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <FormHelperText>
              {touched.placement_location &&
                Boolean(errors.placement_location) && (
                  <Box
                    component="span"
                    sx={{ color: "error.main", mb: 2, display: "block" }}
                    data-error-element={Boolean(errors.placement_location)}
                  >
                    {errors.placement_location}
                  </Box>
                )}
              Select where the placement is located within your newsletter.
            </FormHelperText>
          </Grid>
          <Grid item>
            <InputLabel htmlFor={`${prefix}.ad_description`}>
              Placement Details
            </InputLabel>
            <TextField
              id={`${prefix}.ad_description`}
              fullWidth={true}
              name={`${prefix}.ad_description`}
              multiline
              rows={3}
              value={values.ad_description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.ad_description && Boolean(errors.ad_description)}
              data-error-element={Boolean(errors.ad_description)}
              helperText={
                touched.ad_description && Boolean(errors.ad_description)
                  ? errors.ad_description
                  : null
              }
            />
            <FormHelperText>
              This is a short description of the sponsorship placement. You can
              keep it simple or use it as an opportunity to tell an advertiser
              why they should buy this placement. e.g.: This is our featured
              placement at the top of our email. It is seen by all of our
              subscribers as soon as they open our newsletter.
            </FormHelperText>
          </Grid>
          <Grid item>
            <InputLabel
              htmlFor={`${prefix}.user_ad_click_total`}
              sx={{ display: "flex", alignItems: "center" }}
            >
              Sponsorship Clicks
              <FormHelperText
                sx={{ marginTop: 0.25, paddingLeft: 0.75, fontStyle: "italic" }}
              >
                Optional
              </FormHelperText>
            </InputLabel>
            <TextField
              id={`${prefix}.user_ad_click_total`}
              name={`${prefix}.user_ad_click_total`}
              type="number"
              value={values.user_ad_click_total}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.user_ad_click_total &&
                Boolean(errors.user_ad_click_total)
              }
              data-error-element={Boolean(errors.user_ad_click_total)}
              helperText={
                touched.user_ad_click_total &&
                Boolean(errors.user_ad_click_total)
                  ? errors.user_ad_click_total
                  : ""
              }
            />
            <FormHelperText>
              This is the number of clicks for this sponsorship placement. You
              can calculate this by looking at the average number of clicks for
              your most recent sponsors. First time or unsure? It's better to
              leave this field blank than guess.
            </FormHelperText>
          </Grid>
          <Grid item>
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "1.125rem",
                  color: "#0e1952",
                  lineHeight: 1.2,
                }}
              >
                Price Settings
              </Typography>

              <FormControlLabel
                control={
                  <Tooltip
                    title={
                      smartPricingDisable
                        ? `Available only if the sponsorship price is over $${PUBLISHED_PRICE_MIN}`
                        : ""
                    }
                  >
                    <div>
                      <Switch
                        name={`${prefix}.smart_pricing_enabled`}
                        value={values.smart_pricing_enabled}
                        checked={values.smart_pricing_enabled}
                        onClick={() => smartPricingToggle(false)}
                        disabled={smartPricingDisable}
                        inputRef={buttonRef}
                      />
                    </div>
                  </Tooltip>
                }
                label={
                  <React.Fragment>
                    Smart Pricing
                    <Tooltip
                      title={
                        <div>
                          <Typography
                            sx={{ fontSize: "0.75rem", fontWeight: 500 }}
                          >
                            The Smart Pricing feature lets you set a dynamic
                            price range for your sponsorships instead of a fixed
                            price.{" "}
                          </Typography>
                          <Link
                            href="https://www.paved.com/help/articles/8124581-turn-on-smart-pricing"
                            target="_blank"
                            rel="noreferrer"
                            sx={{
                              color: grey[50],
                              textDecoration: "underline",
                              "&:hover": {
                                textDecorationColor: grey[500],
                                color: grey[500],
                              },
                              fontSize: "0.75rem",
                              fontWeight: 500,
                            }}
                          >
                            Learn how Smart Pricing works
                          </Link>
                        </div>
                      }
                      open={tooltipOpen}
                      onOpen={(e) => handleTooltipOpen(e)}
                      onClose={handleTooltipClose}
                      onClick={(e) => handleTooltipClick(e)}
                      leaveTouchDelay={5000}
                    >
                      <HelpOutline
                        sx={{
                          fontSize: "medium",
                          ml: 0.25,
                          color: "primary.main",
                        }}
                      />
                    </Tooltip>
                  </React.Fragment>
                }
                sx={{ mr: 0.25, mt: 1 }}
              />
              <FormHelperText sx={{ mt: 0.5 }}>
                Automatically offer a last-minute discount as an incentive for
                advertisers. Your price stays within the range you set, and you
                can change it anytime.
              </FormHelperText>
            </Box>
          </Grid>
          <Divider sx={{ mt: 2 }} />
          <Grid item>
            {values.smart_pricing_enabled && (
              <Fragment>
                <PayoutCalculator
                  name={`${prefix}.smart_pricing_lowest_price`}
                  label="Minimum Sponsorship Price"
                  value={values.smart_pricing_lowest_price}
                  error={errors.smart_pricing_lowest_price}
                  touched={touched.smart_pricing_lowest_price}
                  type="sponsored_email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={smartPricingDisable}
                />
                <FormHelperText sx={{ mb: 2 }}>
                  This is the lowest price you're willing to accept.
                </FormHelperText>
              </Fragment>
            )}
            <PayoutCalculator
              name={`${prefix}.published_price`}
              label={
                values.smart_pricing_enabled
                  ? "Maximum Sponsorship Price"
                  : "Sponsorship Price"
              }
              value={values.published_price}
              error={errors.published_price}
              touched={touched.published_price}
              type="sponsored_email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {values.smart_pricing_enabled && (
              <FormHelperText>This is your standard price.</FormHelperText>
            )}
          </Grid>
          <Grid item>
            <CreativeRequirements
              id={values.id}
              prefix={`${prefix}.creativeAssets`}
              values={values.creativeAssets}
              errors={{
                linkAsset: errors.linkAsset,
                nonLinkAsset: errors.nonLinkAsset,
                assetsErrors: errors.creativeAssets as AssetError[],
              }}
              touched={touched.creativeAssets as { [key: string]: boolean }[]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
            {touched.creativeAssets &&
              errors.creativeAssets &&
              !Array.isArray(errors.creativeAssets) && (
                <Typography
                  component="span"
                  sx={{
                    color: "error.main",
                    mt: "12px",
                    mb: 2,
                    display: "block",
                    fontSize: ".75rem",
                  }}
                  data-error-element={Boolean(errors.creativeAssets)}
                >
                  {errors.creativeAssets}
                </Typography>
              )}
          </Grid>
          {exampleUrl && (
            <Grid item>
              <PlacementExample
                siteSlug={siteSlug}
                id={values.id.toString()}
                exampleUrl={exampleUrl}
                hightlightPlacement={highlightPlacement}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

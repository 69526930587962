import { SearchBox } from "react-instantsearch";
import CustomToggleRefinement from "./CustomToggleRefinement";
import CustomRefinementList from "./CustomRefinementList";
import { UserContext } from "context";
import { useContext } from "react";
import "./filters.scss";
import SponsorshipCostSlider from "./SponsorshipCostSlider";
import DateRangePicker from "./DateRange";
import SubscriberSlider from "./SubscriberSlider";
import SortBy from "./SortBy";
import { Box } from "@mui/material";

type PanelProps = React.PropsWithChildren<{
  header: string;
  id: string;
}>;

const FilterPanel = ({ header, id, children }: PanelProps) => {
  return (
    <div className="form-group">
      <strong className="title">{header}</strong>
      <div id={id}>{children}</div>
    </div>
  );
};

const handleClose = (e: React.MouseEvent) => {
  e.preventDefault();
  document.documentElement.classList.remove("filter-done");
  document.querySelector(".filter-open")?.classList.remove("active");
  setTimeout(function () {
    document.documentElement.classList.remove("filter-visible");
  }, 400);
};

type FiltersProps = {
  setPriceValue: React.Dispatch<React.SetStateAction<number[]>>;
  priceValue: number[];
};

export default function Filters({ setPriceValue, priceValue }: FiltersProps) {
  const {
    userData: { isAdmin },
  } = useContext(UserContext);

  return (
    <div className="filter">
      <fieldset>
        <legend className="heading">Filter</legend>
        <div className="filter-space">
          <div className="col">
            <div className="form-group form-search" id="search_box">
              <SearchBox
                placeholder="Search for tag or title"
                autoFocus={false}
                classNames={{
                  root: "form-group form-search",
                  input: "form-input",
                }}
                submitIconComponent={() => null}
                resetIconComponent={() => null}
              />
            </div>
            <Box className="form-group sort-by sort-by--mobile">
              <strong className="title">Sort By</strong>
              <SortBy />
            </Box>
            <div className="form-group">
              <strong className="title">Featured</strong>
              <div className="line" id="verified_toggle">
                <CustomToggleRefinement
                  attribute="is_verified?"
                  label="Verified Stats"
                />
              </div>
              <div className="line" id="picks_toggle">
                <CustomToggleRefinement
                  attribute="picked"
                  label="Paved Picks"
                />
              </div>
              <div className="line" id="has_discount">
                <CustomToggleRefinement
                  attribute="has_discounts?"
                  label="Discount Available"
                />
              </div>
            </div>
            <FilterPanel header="Supported formats" id="format_list">
              <CustomRefinementList
                attribute="formats"
                translations={{ noResultsText: "No results" }}
                sortBy={["name:asc"]}
              />
            </FilterPanel>
          </div>
          <div className="col">
            <FilterPanel header="Category" id="category_list">
              <CustomRefinementList attribute="category_names" />
            </FilterPanel>
          </div>
          {isAdmin && (
            <div className="col">
              <FilterPanel header="Primary Age Group" id="primary_age">
                <CustomRefinementList
                  attribute="audience_primary_age"
                  sortBy={["name:asc"]}
                />
              </FilterPanel>
            </div>
          )}
          <FilterPanel header="Subscriber count" id="list_size">
            <SubscriberSlider attribute="list_size" />
          </FilterPanel>
          <FilterPanel header="Sponsorship cost" id="sponsorship_count">
            <SponsorshipCostSlider
              value={priceValue}
              setValue={setPriceValue}
            />
          </FilterPanel>
          <FilterPanel header="Availability" id="availability">
            <DateRangePicker />
          </FilterPanel>
        </div>
      </fieldset>
      {/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
      <a
        href="#"
        className="filter-close"
        onClick={handleClose}
        aria-label="close"
      />
    </div>
  );
}

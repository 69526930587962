import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  LinearProgress,
  Toolbar,
  Link,
  Button,
} from "@mui/material";
import useComponentDidMount from "hooks/useComponentDidMount";

import axios from "axios";

import { LayoutContext } from "context";
import Logo from "./Logo";
import UserMenu from "./UserMenu";
import AdvertiserSubMenu from "./AdvertiserSubMenu";
import { UserContext } from "context";
import noop from "lodash/noop";
import includeCustomerIo from "utils/customerIo";
import { isAdmin } from "utils/authorize";

export default function Layout() {
  const { userData, setUserData, setPavedFee } = useContext(UserContext);
  const { isDataLoading } = useContext(LayoutContext);
  const { pathname } = useLocation();

  const getPavedFee = () => {
    axios
      .get("/api/v1/publisher/sites/fees")
      .then((response) => {
        setPavedFee(response.data);
      })
      .catch(noop);
  };

  useComponentDidMount(() => {
    if (!!userData.firstName) {
      if (userData.userType === "publisher") {
        getPavedFee();
      }

      return;
    }

    axios
      .get("/api/v1/users/me")
      .then((response) => {
        const {
          first_name,
          last_name,
          company_name,
          user_type,
          has_booker_access,
          has_ad_network_access,
          created_at,
          has_marketplace_access,
          uuid,
          email,
          menu,
          ro_id,
        } = response.data;

        includeCustomerIo(email, created_at);

        if (user_type === "publisher") {
          getPavedFee();
        }
        setUserData({
          firstName: first_name,
          lastName: last_name,
          companyName: company_name,
          userType: user_type,
          createdAt: new Date(created_at),
          isAdmin: isAdmin(),
          has_booker_access,
          has_ad_network_access,
          has_marketplace_access,
          userMenu: menu,
          uuid,
          ro_id,
        });
      })
      .catch(noop);
  });

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
        }}
        position="sticky"
      >
        <Container>
          <Toolbar disableGutters>
            <Box display="flex" justifyContent="space-between" flexGrow={1}>
              <Logo />
              <div>
                {userData.userType === "advertiser" &&
                  userData.has_marketplace_access &&
                  pathname !== "/marketplace" && (
                    <Link href="/marketplace">
                      <Button
                        variant="outlined"
                        sx={{
                          mr: "12px",
                          p: "0 8px",
                          borderColor: "#467fcf",
                          ":hover": {
                            backgroundColor: "#467fcf",
                            color: "white",
                          },
                        }}
                      >
                        Browse Marketplace
                      </Button>
                    </Link>
                  )}
                <UserMenu />
              </div>
            </Box>
          </Toolbar>
        </Container>
        {userData.userType === "advertiser" &&
          pathname !== "/marketplace" &&
          pathname !== "/categories/favorites" && <AdvertiserSubMenu />}
        {isDataLoading && <LinearProgress />}
      </AppBar>
      <Container fixed={true} sx={{ pt: 2, pb: 3, px: 1.5 }}>
        <Outlet />
      </Container>
    </>
  );
}

export { LayoutContext };

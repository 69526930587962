import React from "react";
import { Box, Link } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";

export default function CreativeEditHeader() {
  return (
    <Box
      fontSize="1rem"
      borderBottom="1px solid rgba(0,40,100,.12)"
      padding="1.5rem"
    >
      <Box display="flex" justifyContent="space-between">
        <Box m={0} mb="1.5rem" component="h3">
          Creative Edit
        </Box>
        <Link
          href="https://www.paved.com/help/articles/8120887-understanding-the-creative-upload-process"
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          <FontAwesomeIcon icon={faCircleInfo} /> <u>Help Docs</u>
        </Link>
      </Box>
      <Box>
        Your sponsorship request was approved. Now, let&rsquo;s upload the copy
        for your sponsorship.
      </Box>
    </Box>
  );
}

import React, { useContext, useState } from "react";
import { Button, CircularProgress, Link, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MuiOtpInput } from "mui-one-time-password-input";
import axios from "axios";
import useComponentDidMount from "hooks/useComponentDidMount";

import { useCustomFormik } from "../sign-up/hooks";

import { UserContext } from "context";
import SignButton from "components/SignButton";
import SignInputError from "components/SignInputError";
import StepCaption from "components/StepCaption";
import {
  VALIDATOR_DIGITS_REQUIRED,
  VALIDATOR_STRING_REQUIRED,
  VALIDATOR_OTP,
} from "utils/validators";
import { isAdmin } from "utils/authorize";
import { handleAuth } from "utils/auth";

const FormSchemaOtp = Yup.object().shape({
  verificationCode: VALIDATOR_STRING_REQUIRED.length(
    16,
    "Your one-time recovery code should be 16 symbols."
  ),
});

const FormSchemaTwoFACode = Yup.object().shape({
  verificationCode: VALIDATOR_DIGITS_REQUIRED.length(
    6,
    "Your two-factor authentication code should be 6 digits."
  ),
});

export default function TwoFactorAuthentication() {
  const { setUserData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [isShowTwoFA, setIsShowTwoFA] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  useComponentDidMount(() => {
    if (
      location.pathname === "/users/sign_in/two_factor_authentication" &&
      localStorage.getItem("sign_in_data") === null
    ) {
      navigate(`/users/sign_in`);
    }
  });

  const handleTypeCode = () => {
    formik.resetForm();
    setIsShowTwoFA(!isShowTwoFA);
  };

  const formik = useCustomFormik({
    initialValues: {
      verificationCode: "",
    },
    validationSchema: isShowTwoFA ? FormSchemaTwoFACode : FormSchemaOtp,
    onSubmit: (values) => {
      const dataParsed = JSON.parse(
        localStorage.getItem("sign_in_data") ?? "{}"
      );
      const body = {
        ...dataParsed,
        otp_attempt: values.verificationCode,
        otp_type: isShowTwoFA ? "totp" : "recovery_code",
      };
      setLoading(true);
      axios
        .post("/api/v1/oauth/token", body)
        .then((response) => handleAuth(response))
        .then(() => axios.get("/api/v1/users/me"))
        .then((response) => {
          const {
            first_name,
            last_name,
            company_name,
            user_type,
            has_booker_access,
            has_ad_network_access,
            created_at,
            has_marketplace_access,
            menu,
            uuid,
            ro_id,
          } = response.data;
          setUserData({
            firstName: first_name,
            lastName: last_name,
            companyName: company_name,
            userType: user_type,
            createdAt: new Date(created_at),
            isAdmin: isAdmin(),
            has_booker_access,
            has_ad_network_access,
            has_marketplace_access,
            userMenu: menu,
            uuid,
            ro_id,
          });
          localStorage.removeItem("sign_in_data");

          if (user_type === "publisher") {
            return (window.location.pathname = "/publisher/sites");
          }

          return (window.location.pathname = "/marketplace");
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="signup-steps">
      {isShowTwoFA ? (
        <StepCaption
          heading="Two-Factor Authentication"
          subheading="Please enter your two-factor authentication code."
        />
      ) : (
        <StepCaption
          heading="Recovery Code"
          subheading="Please enter your one-time recovery code."
        />
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          {isShowTwoFA ? (
            <MuiOtpInput
              autoFocus
              length={6}
              value={formik.values.verificationCode}
              TextFieldsProps={{
                type: "number",
                sx: {
                  "& .MuiInputBase-root": {
                    height: "52px",
                  },
                },
                size: "small",
                className: "form-input",
                inputProps: {
                  sx: {
                    pl: 0,
                    pr: 0,
                  },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                },
              }}
              validateChar={(character) => VALIDATOR_OTP(character)}
              onChange={(value) =>
                formik.setFieldValue("verificationCode", value)
              }
            />
          ) : (
            <TextField
              className="form-input"
              name="backup_code"
              value={formik.values.verificationCode}
              sx={{
                "& .MuiInputBase-root": {
                  height: "52px",
                },
              }}
              inputProps={{ maxLength: 16 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value ?? "";
                formik.setFieldValue("verificationCode", value);
              }}
              autoFocus
            />
          )}
          {formik.errors.verificationCode &&
            formik.touched.verificationCode && (
              <SignInputError
                errorMessage={formik.errors.verificationCode.toString()}
              />
            )}
        </div>
        <SignButton
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Continue
        </SignButton>
        {!isShowTwoFA && (
          <Button
            variant="text"
            fullWidth
            size="large"
            onClick={handleTypeCode}
          >
            Use Two-Factor Authentication Code instead
          </Button>
        )}
        {isShowTwoFA ? (
          <p className="subheading">
            To get your authentication code, go to your two-factor
            authentication app and copy the code. If you cannot access your
            authentication app, use a&nbsp;
            <Link
              className="link-button"
              component="button"
              underline="none"
              onClick={handleTypeCode}
            >
              one-time recovery code
            </Link>
            &nbsp;instead.
          </p>
        ) : (
          <p className="subheading">
            If you need support, you can reach us at&nbsp;
            <a href="mailto:help@paved.com?subject=2FA Login Issue">
              help@paved.com
            </a>
          </p>
        )}
      </form>
    </div>
  );
}

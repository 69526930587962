import React, { useContext, useState, useEffect, useRef } from "react";
import { Alert, CircularProgress, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MuiOtpInput } from "mui-one-time-password-input";

import { SignUpContext } from "../context";
import { useAxios, useCustomFormik } from "../hooks";
import SignButton from "components/SignButton";
import SignInputError from "components/SignInputError";
import { getSecondsString } from "utils/formatters";
import { VALIDATOR_OTP } from "utils/validators";
import {
  expiredStorage,
  LS_PHONE_VERIFY_DISABLE,
  RESEND_DISABLE_PERIOD,
} from "./PhoneVerifyForm";
import useComponentDidMount from "hooks/useComponentDidMount";
import noop from "lodash/noop";

const FormSchema = Yup.object().shape({
  smsVerificationCode: Yup.string()
    .length(6, "Your verification code should be 6 digits.")
    .required("This value is required."),
});

export default function CodeVerificationForm() {
  const navigate = useNavigate();
  const intervalId = useRef<NodeJS.Timeout>();
  const { userData } = useContext(SignUpContext);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [count, setCount] = useState(
    expiredStorage.getTimeLeft(LS_PHONE_VERIFY_DISABLE) ?? -1
  );
  const [loading, setLoading] = useState(false);

  const { requestApi } = useAxios();
  const formik = useCustomFormik({
    initialValues: {
      smsVerificationCode: "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      const body = {
        sms_verification_code: values.smsVerificationCode,
      };
      setLoading(true);
      requestApi("post", "/api/v1/users/verify_sms_code", body, [
        "newsletter-onboarding-required",
      ])
        .then((data) => {
          if (data["newsletter-onboarding-required"] === "true") {
            navigate("/users/onboarding/newsletter_details");
          } else {
            window.location.pathname = "/publisher/sites";
          }
        })
        .catch(noop)
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useComponentDidMount(() => {
    expiredStorage.clearExpired();
    const timeLeft = expiredStorage.getTimeLeft(LS_PHONE_VERIFY_DISABLE);
    if (!!timeLeft && timeLeft > 0) {
      startInterval();
    }
  });

  useEffect(() => {
    if (count <= 0) {
      stopInterval();
    }
  }, [count]);

  const startInterval = () => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
    intervalId.current = interval;
  };

  const stopInterval = () => {
    if (!intervalId.current) return;
    clearInterval(intervalId.current);
    intervalId.current = undefined;
  };

  const handleResend = () => {
    setLoading(true);
    const body = {
      phone_number: userData.phoneNumber,
    };
    requestApi("post", "/api/v1/users/send_sms_code", body)
      .then(() => {
        expiredStorage.setItem(
          LS_PHONE_VERIFY_DISABLE,
          "",
          RESEND_DISABLE_PERIOD
        );
        setCount((_) => RESEND_DISABLE_PERIOD);
        startInterval();
        setShowConfirmMessage(true);
        setTimeout(() => {
          setShowConfirmMessage(false);
        }, 3000);
      })
      .catch(noop)
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div className="step active">
        <p className="heading">Verification Code</p>
        <p className="subheading">
          {`We just sent a verification code to ${userData.phoneNumber} `}
          <Link to="/users/onboarding/phone_number">[edit]</Link>
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <MuiOtpInput
            length={6}
            value={formik.values.smsVerificationCode}
            TextFieldsProps={{
              sx: {
                "& .MuiInputBase-root": {
                  height: "52px",
                },
              },
              size: "small",
              className: "form-input",
              inputProps: {
                sx: {
                  pl: 0,
                  pr: 0,
                },
              },
            }}
            validateChar={(character) => VALIDATOR_OTP(character)}
            onChange={(value) =>
              formik.setFieldValue("smsVerificationCode", value)
            }
          />
          {formik.errors.smsVerificationCode &&
            formik.touched.smsVerificationCode && (
              <SignInputError
                errorMessage={formik.errors.smsVerificationCode.toString()}
              />
            )}
        </div>
        <SignButton
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Continue
        </SignButton>
        {showConfirmMessage && (
          <Alert severity="success">Successfully resent code!</Alert>
        )}
        <Button
          variant="text"
          fullWidth
          size="large"
          onClick={handleResend}
          sx={count > 0 ? { textTransform: "none" } : {}}
          disabled={count > 0 || loading}
        >
          Resend code {count > 0 ? `in ${getSecondsString(count)}` : ""}
        </Button>
      </form>
    </div>
  );
}

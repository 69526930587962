import { FormikErrors } from "formik/dist/types";
import { ICreativeRequirement } from "types";
import { FormikValues } from "formik";
import {
  VALIDATOR_STRING_REQUIRED,
  VALIDATOR_URL_REQUIRED,
} from "utils/validators";
import { Suggestion } from "./AICreativeItemTextField";

const CREATIVE_ITEMS_ORDER = ["link", "image", "html", "text"];
const NOT_VALID_LINK_ERROR =
  "The URL is not responding. Please ensure the URL is live before your sponsorship runs.";

export const compareCreativeItemByType = (
  a: ICreativeRequirement,
  b: ICreativeRequirement
) =>
  CREATIVE_ITEMS_ORDER.indexOf(a.creative_type) -
  CREATIVE_ITEMS_ORDER.indexOf(b.creative_type);

export const getMessages = (
  isTouched: boolean,
  creativeItem: ICreativeRequirement,
  errors: FormikErrors<FormikValues>
): (string | undefined)[] => {
  const { uuid, creative_type, validating, isValidUrl } = creativeItem;
  let errorMessage = "";
  let warningMessage = "";

  if (creative_type === "link" && !validating && isValidUrl === false) {
    warningMessage = NOT_VALID_LINK_ERROR;
  }

  if (isTouched && Boolean(errors[uuid])) {
    errorMessage = errors[uuid] as string;
  }

  return [errorMessage, warningMessage];
};

export const getShapeObject = (array: ICreativeRequirement[]) =>
  array.reduce<{
    [key: string]: typeof VALIDATOR_STRING_REQUIRED;
  }>((acc, item) => {
    let value;

    switch (item.creative_type) {
      case "html":
      case "image": {
        value = VALIDATOR_STRING_REQUIRED;
        break;
      }

      case "link": {
        value = VALIDATOR_URL_REQUIRED;
        break;
      }

      default: {
        value = VALIDATOR_STRING_REQUIRED;
      }
    }

    if (!!item.max_length) {
      value = value.max(
        item.max_length,
        ({ value, max }) => `${value.length} / ${max}`
      );
    }

    acc[item.uuid] = value;

    return acc;
  }, {});

export const getMergedSuggestions = (
  historicalSuggestions: Suggestion[],
  AISuggestions: Suggestion[]
) =>
  Object.values(
    [...historicalSuggestions, ...AISuggestions].reduce(
      (acc, item) => {
        if (!acc[item.uuid]) {
          acc[item.uuid] = { ...item };
        } else {
          acc[item.uuid].options = [...acc[item.uuid].options, ...item.options];
        }
        return acc;
      },
      {} as {
        [key: string]: Suggestion;
      }
    )
  );

export const remapSuggestions = (
  data: {
    options: { top_rated: boolean; value: string }[];
    id: number;
  }[],
  type: "saved" | "ai"
) =>
  data.map((asset) => ({
    ...asset,
    uuid: String(asset.id),
    options: asset.options
      .map(({ value, top_rated = false }) => ({
        title: value,
        type,
        top_rated,
      }))
      .sort((a, b) => Number(b.top_rated) - Number(a.top_rated)),
  }));

import "./categories.scss";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import axios from "axios";
import { useState, useEffect } from "react";

type Category = {
  body: string;
  image: string | null;
  link: string;
  title: string;
};

type KeyCategory = {
  link: string;
  title: string;
  short_description: string;
  slug: string;
  image: string | null;
};

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  customPaging: function () {
    return <span className="dot" />;
  },
  arrows: false,
  dots: true,
  speed: 600,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Item = ({ link, image, title, body }: Category) => (
  <div className="item">
    <a href={link} className="card">
      <div className="image">
        {image && <img src={image} width="100" height="156" alt={title} />}
      </div>
      <div className="text">
        <strong className="title">{title}</strong>
        <p>{body}</p>
      </div>
    </a>
  </div>
);

export default function Categories() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [keyCategories, setKeyCategories] = useState<KeyCategory[]>([]);

  useEffect(() => {
    axios.get("/api/v1/featured_categories").then((response) => {
      setCategories(response.data);
    });

    axios.get("/api/v1/key_categories").then((response) => {
      setKeyCategories(response.data);
    });
  }, []);

  return (
    <section className="categories">
      <h3 className="heading">Marketplace</h3>
      <Slider {...settings} className="list carousel">
        {categories.map((item, index) => (
          <Item {...item} key={`${item.title}_${index}}`} />
        ))}
        {keyCategories.map((category) => (
          <Item
            {...category}
            key={category.slug}
            body={category.short_description}
          />
        ))}
      </Slider>
    </section>
  );
}

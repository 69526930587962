import React, { useState } from "react";
import {
  Card,
  CardContent,
  Collapse,
  ListItemButton,
  ListItemText,
  List,
  Box,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CreativeRequirementItem, {
  CreativeRequirementItemView,
} from "./CreativeRequirementItem";
import { ICreativeRequirement } from "../../../../types";

export default function CreativeRequirements({
  creativeItems,
  creativeDeadline,
  exampleUrl,
}: {
  creativeItems: ICreativeRequirement[];
  creativeDeadline: string | null;
  exampleUrl: string | null;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Card>
      <CardContent>
        <ListItemButton onClick={() => setOpen(!open)} sx={{ p: 0 }}>
          <ListItemText>
            <Box component="h3" sx={{ m: 0 }}>
              Creative Requirements
            </Box>
          </ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ mt: "1.15rem" }}>
            <ListItemText>
              The publisher has provided the following creative requirements:
            </ListItemText>
            <Box pl="1.15rem">
              {creativeItems.map((creativeItem) => (
                <CreativeRequirementItem
                  key={creativeItem.uuid}
                  creativeItem={creativeItem}
                />
              ))}
              {exampleUrl && (
                <CreativeRequirementItemView
                  name="Example"
                  value={exampleUrl}
                  isLink={true}
                />
              )}
              {creativeDeadline && (
                <CreativeRequirementItemView
                  name="Creative Deadline"
                  value={creativeDeadline}
                />
              )}
            </Box>
          </List>
        </Collapse>
      </CardContent>
    </Card>
  );
}

import { Box, Link, Typography } from "@mui/material";
import { useRef, useEffect, useState } from "react";

const MAX_LENGTH = 30;

function formatFilename(filename: string = "", maxLength = MAX_LENGTH) {
  const ELLIPSIS_CHARS_LENGTH = 3;
  const EXTENSION_CHARS_LENGTH = 4;

  if (filename.length <= maxLength) {
    return filename;
  }
  const extension = filename.split(".").pop();
  const charsToShow =
    maxLength - ELLIPSIS_CHARS_LENGTH - EXTENSION_CHARS_LENGTH;

  const name = filename.replace(`.${extension}`, "");
  const start = name.substring(0, charsToShow / 2);
  const end = name.substring(name.length - charsToShow / 2);

  return `${start}...${end}.${extension}`;
}

type AssetInfoProps = {
  newFilename?: string;
  srcUrl?: string | null;
  placeholder: string;
  extension: string;
};

export default function AssetInfo({
  newFilename = "",
  srcUrl = "",
  placeholder,
  extension,
}: AssetInfoProps) {
  const initialFilename = decodeURIComponent(srcUrl?.split("/").pop() ?? "");
  const name = newFilename || initialFilename;
  const [text, setText] = useState(name);

  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (name && boxRef.current) {
        const charWidth = 7; // Approximate width of one character
        const maxChars = Math.floor(boxRef.current.offsetWidth / charWidth);
        setText(formatFilename(name, maxChars));
      }
    });
    if (boxRef.current) resizeObserver.observe(boxRef.current);
    return () => resizeObserver.disconnect();
  }, [name]);

  const typography = (
    <Typography
      sx={{
        fontWeight: name ? 600 : "initial",
        width: "10 0%",
        whiteSpace: "noWrap",
      }}
      title={name || ""}
    >
      {text || placeholder}
    </Typography>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ ml: "15px", mr: "auto", width: "50%" }}
      ref={boxRef}
    >
      {!newFilename && initialFilename && srcUrl ? (
        <Link
          href={srcUrl as string}
          target="_blank"
          underline="none"
          sx={{ fontSize: "16px" }}
        >
          {typography}
        </Link>
      ) : (
        typography
      )}
      <Typography>{extension}</Typography>
    </Box>
  );
}

import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import AmountInput from "./AmountInput";
import { UserContext } from "context";
import Decimal from "decimal.js";

function prepareNumberRepresentation(value: number): string {
  const intPart = Math.floor(value);

  return intPart === value ? intPart.toString() : value.toFixed(2);
}

function calculatePayoutAndFee(
  value: number,
  pavedFee: number
): [string, string] {
  if (!value) {
    return ["0", "0"];
  }

  const bdValue = new Decimal(value);
  const bdPavedFee = new Decimal(pavedFee);

  const newPayout = bdValue
    .times(new Decimal(100).minus(bdPavedFee).div(100))
    .toDecimalPlaces(2, Decimal.ROUND_UP);
  const newFee = bdValue.minus(newPayout);

  return [
    prepareNumberRepresentation(newPayout.toNumber()),
    prepareNumberRepresentation(newFee.toNumber()),
  ];
}

export default function PayoutCalculator({
  name,
  onChange,
  onBlur,
  value,
  error,
  touched,
  type,
  label = "Sponsorship Price",
  disabled = false,
}: {
  name: string;
  onChange: (e: React.ChangeEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  value: number;
  error?: string;
  touched?: boolean;
  type: "dedicated_email" | "sponsored_email" | "sponsored_post";
  label?: string;
  disabled?: boolean;
}) {
  const [fee, setFee] = useState<string>("0");
  const [payout, setPayout] = useState<string>("0");
  const { pavedFee } = useContext(UserContext);

  useEffect(() => {
    const [newPayout, newFee] = calculatePayoutAndFee(value, pavedFee[type]);
    setFee(newFee);
    setPayout(newPayout);
  }, [value, pavedFee, type]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <AmountInput
            name={name}
            label={label}
            value={value}
            error={error}
            touched={touched}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AmountInput
            name="fee-amount"
            label="Marketplace Fee"
            disabled={true}
            value={fee}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AmountInput
            name="payout-amount"
            label="Your Payout"
            disabled={true}
            value={payout}
            isPayout
          />
        </Grid>
      </Grid>
    </>
  );
}

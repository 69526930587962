import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem, ListItemIcon, Fade } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "@fortawesome/pro-regular-svg-icons";
import { UserContext } from "context";
import { UserMenuItem } from "types";
import { toCamel } from "utils";

type RemappedUserMenuItem = Omit<UserMenuItem, "icon"> & {
  icon: icons.IconDefinition;
};

const remapMenu = (data: UserMenuItem[]): RemappedUserMenuItem[] =>
  data.map((item) => ({
    ...item,
    icon: icons[
      toCamel(item.icon) as keyof typeof icons
    ] as icons.IconDefinition,
  }));

export default function UserMenu() {
  const {
    userData: { firstName, lastName, companyName, userMenu },
  } = useContext(UserContext);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = useState<RemappedUserMenuItem[]>([]);

  useEffect(() => {
    const menuItems = remapMenu(userMenu);
    setMenuItems(menuItems);
  }, [userMenu]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      {(firstName || lastName || companyName) && (
        <>
          <Fade in>
            <Button
              endIcon={<ExpandMoreIcon sx={{ color: "#B3BECE" }} />}
              onClick={handleOpenUserMenu}
              disableRipple
              sx={{
                textTransform: "none",
                color: "#0e1952",
                fontSize: 15,
                fontWeight: 500,
                ":hover": {
                  backgroundColor: "initial",
                  color: "#0e63f4",
                },
              }}
            >
              {firstName} {lastName}, {companyName}
            </Button>
          </Fade>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            keepMounted
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            sx={{
              ".MuiPaper-root": {
                borderRadius: "10px",
                border: "none",
                boxShadow: "0 15px 40px rgba(52, 77, 131, .15)",
                marginTop: "4px",
              },
              "& .MuiList-root": {
                padding: "8px",
                maxHeight: "60vh",
              },
            }}
            slotProps={{
              paper: {
                sx: {
                  minWidth: 250,
                  border: "1px solid rgba(0,40,100,.12)",
                  boxShadow: "0 1px 2px 0 rgb(0 0 0 / 5%)",
                },
              },
            }}
          >
            {menuItems.map((item) => (
              <Link key={item.name} to={item.link} reloadDocument={item.reload}>
                <MenuItem
                  sx={{
                    color: "#0e1952",
                    borderRadius: "8px",
                    "&:hover": {
                      color: "#0e63f4",
                      backgroundColor: "#f8faff",
                    },
                    padding: "12px",
                    lineHeight: "26px",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                  onClick={handleCloseUserMenu}
                >
                  <ListItemIcon
                    sx={{
                      color: "darkgrey",
                      minWidth: "32px !important",
                    }}
                  >
                    <FontAwesomeIcon icon={item.icon} />
                  </ListItemIcon>
                  {item.name}
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </>
      )}
    </>
  );
}

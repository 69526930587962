import React, { useLayoutEffect, useContext } from "react";
import * as yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { Button, CircularProgress } from "@mui/material";
import SignInField, { Values } from "./SignInField";
import { UserContext } from "context";
import SSOButtons from "components/SSOButtons";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid e-mail")
    .required("E-mail is required."),
});

export default function SignInForm() {
  const { setUserData } = useContext(UserContext);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setUserData({
      firstName: "",
      lastName: "",
      companyName: "",
      userType: undefined,
      createdAt: new Date(),
      isAdmin: false,
      has_booker_access: false,
      has_ad_network_access: false,
      has_marketplace_access: false,
      userMenu: [],
      uuid: "",
      ro_id: null,
    });
    Cookies.remove("bearer");
    Cookies.remove("bearer_expires");
    Cookies.remove("bearer_refresh");
    delete axios.defaults.headers.common["Authorization"];
  }, [setUserData]);

  const handleSubmit = (data: Values, actions: FormikHelpers<Values>) => {
    const url = "/api/v1/users/email_lookup";
    const body = {
      ...data,
    };
    actions.setSubmitting(true);
    axios
      .post(url, body, { processErrorInComponentStatuses: [428] })
      .catch((err) => {
        if (err.response.status === 428) {
          const location = err.response.data.location;
          if (location.includes("sso")) {
            window.location.href = location;
          } else {
            localStorage.setItem("sign_in_data", JSON.stringify(body));
            navigate(location, { state: { email: data.email } });
          }
        }
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, isSubmitting, touched, handleBlur }) => (
          <Form>
            <div className="signup-steps">
              <div className="step active">
                <p className="heading">Welcome back</p>
                <p className="subheading" style={{ marginBottom: 0 }}>
                  Enter your email below to login.
                </p>
                <p className="error devise-error" />
                <SignInField
                  name="email"
                  type="email"
                  touched={touched}
                  errors={errors}
                  onBlur={handleBlur}
                />
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{
                    backgroundColor: "primary.dark",
                  }}
                  endIcon={
                    isSubmitting ? (
                      <CircularProgress size={12} sx={{ color: "#fff" }} />
                    ) : null
                  }
                >
                  Log in
                </Button>
                <SSOButtons />
                <p className="note" style={{ marginTop: 0 }}>
                  <b>Don't have an account yet?</b>{" "}
                  <a href="/users/sign_up">Sign up</a> to get started.
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

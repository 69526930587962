import React, { useContext, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  InputLabel,
  TextField,
  Typography,
  FormHelperText,
  Button,
} from "@mui/material";
import axios from "axios";
import { LayoutContext, useSnackbar } from "context";
import PayoutCalculator from "../components/payoutCalculator/PayoutCalculator";
import EmailSampleUploader from "../components/EmailSampleUploader";
import {
  VALIDATOR_NUMBER_REQUIRED,
  VALIDATOR_STRING_REQUIRED,
} from "utils/validators";
import { SiteContext } from "../components/SiteContext";
import useComponentDidMount from "hooks/useComponentDidMount";
import FormWrapper from "components/FormWrapper";

const validationSchema = yup.object().shape({
  list_size: VALIDATOR_NUMBER_REQUIRED,
  user_open_total: VALIDATOR_NUMBER_REQUIRED,
  user_click_total: VALIDATOR_NUMBER_REQUIRED,
  published_price: VALIDATOR_NUMBER_REQUIRED,
  ad_description: VALIDATOR_STRING_REQUIRED,
});

export default function DedicatedEmail() {
  const openSnackbar = useSnackbar();
  const navigate = useNavigate();
  const { siteSlug } = useParams();
  const { siteData } = useContext(SiteContext);
  const { setIsDataLoading } = useContext(LayoutContext);
  const [initialValues, setInitialValues] = useState({
    id: null,
    list_size: "",
    user_open_total: "",
    user_click_total: "",
    ad_description: "",
    published_price: 0,
    example_url: "",
  });

  useComponentDidMount(() => {
    if (siteSlug) {
      setIsDataLoading(true);

      axios
        .get(
          `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/dedicated_email`
        )
        .then((response) => {
          // TODO: discuss initial values
          Object.keys(response.data).forEach((key: string) => {
            if (response.data[key] === null) {
              response.data[key] = "";
            }
          });
          setIsDataLoading(false);
          setInitialValues(response.data);
        });
    }
  });

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (data) => {
      if (initialValues === data) {
        return handleNextButton();
      }

      setIsDataLoading(true);

      const url = `/api/v1/publisher/sites/${siteSlug}/sponsorship_options/dedicated_email`;

      axios.patch(url, data).then(function () {
        setIsDataLoading(false);
        handleNextButton();
      });
    },
  });

  const nextRoute = useMemo(() => {
    let route = "review";

    if (siteData.email_ads) {
      route = "sponsored_email";
    }

    return `/publisher/sites/${siteSlug}/edit/${route}`;
  }, [siteSlug, siteData]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseFloat(event.target.value) < 0) {
      formik.setFieldValue(event.target.name, "0");
    } else {
      formik.handleChange(event);
    }
  };

  const handleNextButton = () => {
    if (siteData.status === "Live") {
      openSnackbar("Sponsorship updated!");
    } else {
      navigate(nextRoute);
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        color="text.primary"
        sx={{ marginBottom: 1.5 }}
      >
        Dedicated Email
      </Typography>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Card>
          <CardHeader
            title="Performance & Pricing"
            titleTypographyProps={{ variant: "h6" }}
          />
          <CardContent>
            <Grid container spacing={4} flexDirection="column">
              <Grid item>
                <InputLabel htmlFor="list_size">
                  Number of Subscribers
                </InputLabel>
                <TextField
                  id="list_size"
                  name="list_size"
                  type="number"
                  value={formik.values.list_size}
                  error={
                    formik.touched.list_size && Boolean(formik.errors.list_size)
                  }
                  data-error-element={Boolean(formik.errors.list_size)}
                  helperText={
                    formik.touched.list_size && formik.errors.list_size
                  }
                  onBlur={formik.handleBlur}
                  onChange={onChange}
                />
                <FormHelperText>
                  The number of active subscribers to your newsletter. It should
                  not include bounces or people who have unsubscribed.
                </FormHelperText>
              </Grid>
              <Grid item>
                <InputLabel htmlFor="user_open_total">
                  Total Unique Opens Per Dedicated Email
                </InputLabel>
                <TextField
                  id="user_open_total"
                  name="user_open_total"
                  type="number"
                  value={formik.values.user_open_total}
                  error={
                    formik.touched.user_open_total &&
                    Boolean(formik.errors.user_open_total)
                  }
                  helperText={
                    formik.touched.user_open_total &&
                    formik.errors.user_open_total
                  }
                  onBlur={formik.handleBlur}
                  onChange={onChange}
                />
                <FormHelperText>
                  This is the number of subscribers who open each newsletter. It
                  should be the unique count and shouldn't be a percentage. Use
                  an average from your most recent sends.
                </FormHelperText>
              </Grid>
              <Grid item>
                <InputLabel htmlFor="user_click_total">
                  Total Unique Clicks Per Dedicated Email
                </InputLabel>
                <TextField
                  id="user_click_total"
                  name="user_click_total"
                  type="number"
                  value={formik.values.user_click_total}
                  error={
                    formik.touched.user_click_total &&
                    Boolean(formik.errors.user_click_total)
                  }
                  helperText={
                    formik.touched.user_click_total &&
                    formik.errors.user_click_total
                  }
                  onBlur={formik.handleBlur}
                  onChange={onChange}
                />
                <FormHelperText>
                  This is the number of subscribers who click on any link per
                  newsletter. It should be the unique count and shouldn't be a
                  percentage. Use an average from your most recent sends.
                </FormHelperText>
              </Grid>
              <Grid item>
                <EmailSampleUploader
                  previewUrl={formik.values.example_url}
                  siteSlug={siteSlug}
                  id={formik.values.id}
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="ad_description">Details</InputLabel>
                <TextField
                  fullWidth={true}
                  id="ad_description"
                  name="ad_description"
                  type="text"
                  multiline={true}
                  rows={6}
                  value={formik.values.ad_description}
                  error={
                    formik.touched.ad_description &&
                    Boolean(formik.errors.ad_description)
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <FormHelperText>
                  This is a short description of the sponsorship placement. You
                  can keep it simple or use it as an opportunity to tell an
                  advertiser why they should sponsor your newsletter.
                </FormHelperText>
                <FormHelperText sx={{ marginTop: 1.5 }}>
                  e.g.: We have seen fantastic subscriber engagement with brands
                  and offers that match our audience. We can A/B test subject
                  lines and give advice on how to optimize the creative.
                </FormHelperText>
              </Grid>
              <Grid item>
                <PayoutCalculator
                  name="published_price"
                  value={formik.values.published_price}
                  error={formik.errors.published_price}
                  touched={formik.touched.published_price}
                  type="dedicated_email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            sx={{
              textTransform: "capitalize",
            }}
          >
            {siteData.status === "Live" ? "Update" : "Next"}
          </Button>
        </Box>
      </FormWrapper>
    </>
  );
}

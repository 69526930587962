import React, { ChangeEvent, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  List,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import debounce from "lodash/debounce";
import { AdvertiserItem } from "../types";
import PreviousAdvertiserItem from "./PreviousAdvertiserItem";
import { LayoutContext } from "context";
import { SiteContext } from "../components/SiteContext";
import useComponentDidMount from "hooks/useComponentDidMount";
import noop from "lodash/noop";

const clearbitAxios = axios.create({
  transformRequest: (data, headers) => {
    delete headers?.common;
  },
});

const getAdvertisersSuggestion = debounce(
  (query: string | undefined, callbackFn: (data: AdvertiserItem[]) => void) => {
    if (!query) {
      return;
    }

    clearbitAxios({
      method: "get",
      url: "https://autocomplete.clearbit.com/v1/companies/suggest",
      params: { query },
      responseType: "json",
      headers: {},
    }).then((response) => {
      callbackFn(response.data);
    });
  },
  300
);

export default function PreviousAdvertisers() {
  const { siteSlug } = useParams();
  const { siteData } = useContext(SiteContext);
  const { setIsDataLoading } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);
  const [isLostFocus, setIsLostFocus] = useState(false);

  const [advertisersSuggestion, setAdvertisersSuggestion] = useState<
    AdvertiserItem[]
  >([]);

  const [previousAdvertisersList, setPreviousAdvertisersList] = useState<
    AdvertiserItem[]
  >([]);

  useComponentDidMount(() => {
    setIsDataLoading(true);

    axios({
      method: "get",
      url: `/api/v1/publisher/sites/${siteSlug}/previous_advertisers`,
      responseType: "json",
    })
      .then((response) => {
        setIsDataLoading(false);
        setPreviousAdvertisersList(response.data);
      })
      .catch(noop);
  });

  const createPreviousAdvertiser = (data: AdvertiserItem) => {
    setIsDataLoading(true);

    axios({
      method: "post",
      url: `/api/v1/publisher/sites/${siteSlug}/previous_advertisers`,
      data,
    }).then((response) => {
      setIsDataLoading(false);

      setPreviousAdvertisersList((prev) => {
        const newList = [...prev];
        newList.push(response.data);
        return newList;
      });

      setAdvertisersSuggestion([]);
    });
  };

  const removePreviousAdvertiser = (
    previousAdvertiserId: number | undefined
  ) => {
    if (previousAdvertiserId === undefined) {
      return;
    }

    axios({
      method: "delete",
      url: `/api/v1/publisher/sites/${siteSlug}/previous_advertisers/${previousAdvertiserId}`,
    }).then(() => {
      setPreviousAdvertisersList((prev) =>
        prev.filter((item) => item.id !== previousAdvertiserId)
      );
    });
  };

  const getAdvertiserData = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!event.target.value) {
      setAdvertisersSuggestion([]);
      return;
    }
    setLoading(true);
    try {
      getAdvertisersSuggestion(event.target.value, (data: AdvertiserItem[]) => {
        setLoading(false);
        setAdvertisersSuggestion(
          data.map((item) => ({ ...item, url: item.domain }))
        );
      });
    } catch {
      setLoading(false);
    }
  };

  const onClickNext = () => {
    return (window.location.href = `/publisher/sites/${siteSlug}/esp`);
  };

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        color="text.primary"
        sx={{ marginBottom: 1.5 }}
      >
        Previous Advertisers
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2} flexDirection="column">
            <Grid item>
              <Typography>
                Add credibility by telling potential advertisers about brands
                that have advertised with you previously.
              </Typography>
            </Grid>
            <Grid item>
              <InputLabel htmlFor="advertisers-suggestion">
                Advertiser Name
              </InputLabel>
              <Autocomplete
                disablePortal={true}
                id="advertisers-suggestion"
                open={advertisersSuggestion?.length > 0 && !isLostFocus}
                options={advertisersSuggestion}
                filterOptions={(x) => x}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    return setAdvertisersSuggestion([]);
                  }
                  createPreviousAdvertiser(newValue);
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: AdvertiserItem
                ) => (
                  <PreviousAdvertiserItem
                    optionProps={props}
                    item={option}
                    key={option.name}
                  />
                )}
                getOptionLabel={() => ""}
                popupIcon={null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={getAdvertiserData}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "0.375rem 0.75rem !important",
                      },
                    }}
                    fullWidth={true}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                onBlur={() => setIsLostFocus(true)}
                onFocus={() => setIsLostFocus(false)}
              />
            </Grid>
            <Grid item>
              <List>
                {previousAdvertisersList.map((item) => (
                  <PreviousAdvertiserItem
                    item={item}
                    key={item.name}
                    onRemove={removePreviousAdvertiser}
                  />
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {siteData.status !== "Live" && (
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Button color="primary" variant="contained" onClick={onClickNext}>
            Next
          </Button>
        </Box>
      )}
    </>
  );
}

import React, { createContext, ReactNode, useEffect, useState } from "react";
import noop from "lodash/noop";
import { IError, IOnboardingValues, IUser } from "../types";

type SegmentsData = {
  ro_id: number;
  created_at: string;
};

interface ISignUpContext {
  userData: IUser;
  segmentsData?: SegmentsData;
  setUserData: (data: IUser) => void;
  setSegmentsData: (data: SegmentsData) => void;
  onboardingValues: IOnboardingValues | undefined;
  setOnboardingValues: (values: IOnboardingValues) => void;
  errors: IError;
  setErrors: (error: IError) => void;
}

const DEFAULT_STATE: ISignUpContext = {
  userData: {
    userType: undefined,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
  },
  segmentsData: undefined,
  setSegmentsData: noop,
  setUserData: noop,
  onboardingValues: undefined,
  setOnboardingValues: noop,
  errors: {},
  setErrors: noop,
};

export const SignUpContext = createContext(DEFAULT_STATE);
export interface ISignUpProviderProps {
  children?: ReactNode;
}

export const SignUpProvider = ({ children }: ISignUpProviderProps) => {
  const [userData, setUserData] = useState(DEFAULT_STATE.userData);
  const [segmentsData, setSegmentsData] = useState(DEFAULT_STATE.segmentsData);
  const [onboardingValues, setOnboardingValues] = useState(
    DEFAULT_STATE.onboardingValues
  );
  const [errors, setErrors] = useState(DEFAULT_STATE.errors);

  useEffect(() => {
    setErrors({});
  }, [setErrors]);

  return (
    <SignUpContext.Provider
      value={{
        userData,
        segmentsData,
        onboardingValues,
        errors,
        setUserData,
        setSegmentsData,
        setOnboardingValues,
        setErrors,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

import React from "react";
import { Outlet } from "react-router-dom";
import "./styles.scss";
import { SignUpProvider } from "./sign-up/context";

export default function Users() {
  return (
    <div className="page-signup">
      <main className="page page-nobottom">
        <div className="form form-signup">
          <header className="header">
            <div className="container">
              <h1 className="brand">
                <a href="/">Paved</a>
              </h1>
            </div>
          </header>
          <div className="space">
            <figure className="image">
              <img className="form-signup-theme-img" alt="Sign Up" />
            </figure>
            <SignUpProvider>
              <Outlet />
            </SignUpProvider>
          </div>
        </div>
      </main>
    </div>
  );
}

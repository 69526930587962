export default function Header() {
  return (
    <div className="category">
      <a href="/marketplace" className="back">
        Back to Marketplace
      </a>
      <h2 className="heading">Favorite Publishers</h2>
    </div>
  );
}

import React from "react";
import { Box, Container, Link } from "@mui/material";

const linkItems = [
  { urlPart: "ads", caption: "Ad Network" },
  { urlPart: "campaigns", caption: "Sponsorships" },
];

export default function AdvertiserSubMenu() {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(48, 54, 69) !important",
        padding: "16px 0",
      }}
    >
      <Container>
        <Box display="flex" flexGrow={1}>
          {linkItems.map((item) => (
            <Link
              key={item.urlPart}
              href={`/advertiser/${item.urlPart}`}
              rel="noreferrer"
              sx={{
                fontSize: "15px",
                textDecoration: "none",
                color: window.location.pathname.includes(`/${item.urlPart}`)
                  ? "white"
                  : "rgba(255, 255, 255, 0.55)",
                ":hover": { color: "rgba(255, 255, 255, .75)" },
                pr: "12px",
              }}
            >
              {item.caption}
            </Link>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SmartPricingModal({
  open,
  handleClose,
  buttonRef,
  smartPricingToggle,
  buttonDisabled,
}: {
  open: boolean;
  handleClose: (event: any, reason: any) => void;
  buttonRef: React.RefObject<HTMLInputElement>;
  smartPricingToggle: (checked: boolean) => void;
  buttonDisabled: boolean;
}) {
  const [boxes, setChecked] = useState({
    upToDate: false,
    minimumPrice: false,
    respondQuickly: false,
  });

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 680,
    maxWidth: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 7,
    borderRadius: 0.5,
    padding: "26px",
  };

  const enableAndClose = () => {
    handleClose(0, "close");
    smartPricingToggle(true);
  };

  const discardAndClose = () => {
    handleClose(0, "close");
  };

  const smartPricingDisable =
    !boxes.upToDate || !boxes.minimumPrice || !boxes.respondQuickly;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container direction="column" spacing={0}>
            <IconButton
              onClick={discardAndClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h5"
              component="h2"
              sx={{ marginBottom: 1.5, fontWeight: 500, color: "#000" }}
            >
              Enable Smart Pricing
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, color: "#000" }}
            >
              Smart Pricing is designed to sell your remnant inventory. It
              automatically reduces the price of unsold sponsorships running in
              the next 14 days.
            </Typography>
            <Typography sx={{ mt: 2, color: "#000" }}>
              With Smart Pricing, things move fast. Before you enable it, let's
              make sure you agree to the following:
            </Typography>
            <FormControlLabel
              sx={{
                mt: window.matchMedia("(max-width: 401px)").matches ? 2 : 1,
              }}
              control={
                <Checkbox
                  name={"upToDate"}
                  checked={boxes.upToDate}
                  onChange={() =>
                    setChecked((prevState) => ({
                      ...prevState,
                      upToDate: !prevState.upToDate,
                    }))
                  }
                />
              }
              label="I'll keep my availability calendar up-to-date. "
            />
            <FormControlLabel
              control={
                <Checkbox
                  name={"minimumPrice"}
                  checked={boxes.minimumPrice}
                  onChange={() =>
                    setChecked((prevState) => ({
                      ...prevState,
                      minimumPrice: !prevState.minimumPrice,
                    }))
                  }
                />
              }
              label="I'll honor the Minimum Price I enter."
            />
            <FormControlLabel
              control={
                <Checkbox
                  name={"respondQuickly"}
                  checked={boxes.respondQuickly}
                  onChange={() =>
                    setChecked((prevState) => ({
                      ...prevState,
                      respondQuickly: !prevState.respondQuickly,
                    }))
                  }
                />
              }
              label="I'll respond to sponsorship requests quickly."
            />
          </Grid>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button
              color="primary"
              variant="outlined"
              sx={{
                color: "text.primary",
              }}
              onClick={discardAndClose}
            >
              Maybe later
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={smartPricingDisable}
              onClick={enableAndClose}
            >
              Enable
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

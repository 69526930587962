import React from "react";
import { Link, Box } from "@mui/material";
import LogoImage from "assets/images/logo.png";

const Logo = () => (
  <Link href="/">
    <Box maxWidth={75} maxHeight={46} paddingTop="6px">
      <img src={LogoImage} alt="logo" width="100%" />
    </Box>
  </Link>
);

export default Logo;

import cls from "classnames";
import { Hits, Pagination, useInstantSearch } from "react-instantsearch";
import HitComponent, { Hit } from "./Hit";
import { useEffect, useState } from "react";
import axios from "axios";
import CustomStats from "./CustomStats";
import SortBy from "./SortBy";
import { Box } from "@mui/material";

export default function Content({
  showHeader = true,
  handlePageClick,
}: {
  showHeader: boolean;
  handlePageClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}) {
  const { results } = useInstantSearch();

  const [favorites, setFavorites] = useState<number[]>([]);

  useEffect(() => {
    axios.get("/api/v1/sites/favorites").then((response) => {
      setFavorites(response.data);
    });
  }, []);

  const handleOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    document.documentElement.classList.add("filter-visible");
    document.querySelector(".filter-open")?.classList.add("active");
    setTimeout(function () {
      document.documentElement.classList.add("filter-done");
    }, 10);
  };

  const noResults = !results.__isArtificial && results.nbHits === 0;

  return (
    <>
      <section className="publishers">
        <Box>
          <div id="header">
            {results.query && (
              <h3 className="title">
                Search results for "<span>{results.query}</span>"
              </h3>
            )}
            {!results.query && showHeader && (
              <>
                <h3 className="title">Featured Newsletters</h3>
                <p className="subtitle">
                  Check out some of the top newsletters on Paved.
                </p>
              </>
            )}
          </div>
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <a href="#" className={cls("filter-open")} onClick={handleOpen}>
            Filter
          </a>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomStats />
            <Box className="sort-by">
              <SortBy />
            </Box>
          </Box>
        </Box>
        <div className="list" id="results">
          <Hits
            hitComponent={({ hit }: { hit: Hit }) => (
              <HitComponent hit={hit} favorites={favorites} />
            )}
          />
        </div>
      </section>
      <Pagination
        classNames={{
          nextPageItem: "next",
          selectedItem: "active",
          previousPageItem: "prev",
          list: cls("pages", {
            "pages--hidden": noResults || results.nbPages <= 1,
          }),
        }}
        padding={1}
        showFirst={false}
        showLast={false}
        onClick={handlePageClick}
      />
    </>
  );
}

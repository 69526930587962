import React from "react";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarPlus,
  faFileUpload,
  faFileInvoiceDollar,
  faEnvelopeOpenText,
  faThumbsUp,
  faCalendarCheck,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTheme } from "@mui/material/styles";

export type TopMenuItemStatus =
  | "Requested"
  | "Creative"
  | "Payment"
  | "Awaiting Draft"
  | "Approval"
  | "Publisher Review"
  | "Scheduled"
  | "Sent";

type TopMenuItemProps = {
  status: TopMenuItemStatus;
  completed?: boolean;
  active?: boolean;
};

const statusDict: Record<TopMenuItemStatus, { label: string; icon: IconProp }> =
  {
    Requested: { label: "Requested", icon: faCalendarPlus },
    Creative: { label: "Creative", icon: faFileUpload },
    Payment: { label: "Payment", icon: faFileInvoiceDollar },
    "Awaiting Draft": { label: "Awaiting Draft", icon: faEnvelopeOpenText },
    Approval: { label: "Approval", icon: faThumbsUp },
    "Publisher Review": { label: "Publisher Review", icon: faThumbsUp },
    Scheduled: { label: "Scheduled", icon: faCalendarCheck },
    Sent: { label: "Sent", icon: faCheck },
  };

export default function TopMenuItem({
  status,
  completed = false,
  active = false,
}: TopMenuItemProps) {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        sx={{
          bgcolor: completed || active ? "#edf2fa" : "white",
          width: 38,
          height: 38,
          borderRadius: "50%",
          border: "solid 1px",
          borderColor:
            completed || active
              ? theme.palette.primary.main
              : "rgba(0,40,100,0.12)",
          textAlign: "center",
          pt: "5px",
          zIndex: 2,
        }}
      >
        <FontAwesomeIcon
          icon={statusDict[status]?.icon ?? faThumbsUp}
          color={completed || active ? theme.palette.primary.main : "#9aa0ac"}
        />
      </Box>
      <Box sx={{ fontWeight: active ? "bold" : "normal" }} fontSize="small">
        {statusDict[status].label ?? status}
      </Box>
    </Box>
  );
}

import { useSortBy } from "react-instantsearch";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "components/CustomSelect";

const INDEX_NAME = `Site_${process.env.REACT_APP_APP_ENV}`;

const items = [
  { label: "Recommended", value: INDEX_NAME },
  {
    label: "Discount",
    value: `${INDEX_NAME}_max-discount_desc`,
  },
  {
    label: "Subscribers: High to Low",
    value: `${INDEX_NAME}_list-size_desc`,
  },
  {
    label: "Subscribers: Low to High",
    value: `${INDEX_NAME}_list-size_asc`,
  },
  {
    label: "Top Rated",
    value: `${INDEX_NAME}_review-rank-score_desc`,
  },
];

function SortBy() {
  const { currentRefinement, options, refine } = useSortBy({ items });

  return (
    <FormControl fullWidth>
      <Select
        value={currentRefinement}
        onChange={(event) => {
          refine(event.target.value as unknown as string);
        }}
        renderValue={(selected) => {
          const label = options.find((x) => x.value === selected)?.label;
          return `Sort by: ${label}`;
        }}
        sx={{
          ".MuiSelect-icon": {
            color: "#0e63f4",
          },
          ".MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
            py: "9px",
            pl: "20px",
            pr: "44px",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SortBy;

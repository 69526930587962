import React, { ReactNode } from "react";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { grey } from "@mui/material/colors";
import Tooltip from "components/Tooltip";

export default function SideMenuItem({
  iconComponent,
  to,
  caption,
  disable,
  nativeLink,
  target,
  listItemButtonSX,
  listItemTextSX,
  tooltipTitle,
}: {
  iconComponent: ReactNode;
  to: string;
  caption: string;
  disable?: boolean;
  nativeLink?: boolean;
  target?: string;
  listItemButtonSX?: React.CSSProperties;
  listItemTextSX?: React.CSSProperties;
  tooltipTitle?: string;
}) {
  const isActive = window.location.pathname === to;
  let color: string = "text.primary";

  if (isActive) {
    color = "primary.main";
  }

  if (disable) {
    color = grey[500];
  }

  if (disable) {
    target = "";
  }

  const listItemProps = disable
    ? {
        component: Box,
      }
    : {
        component: Link,
        reloadDocument: nativeLink,
        target,
        to,
      };

  return (
    <Tooltip title={tooltipTitle}>
      <ListItem disablePadding={true} selected={isActive} {...listItemProps}>
        <ListItemButton sx={listItemButtonSX}>
          <ListItemIcon sx={{ color, minWidth: "30px !important" }}>
            {iconComponent}
          </ListItemIcon>
          <ListItemText
            primary={caption}
            primaryTypographyProps={{
              sx: { fontWeight: isActive ? 600 : 400, ...listItemTextSX },
            }}
            sx={{ color }}
          />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
}

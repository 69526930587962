import React from "react";
import Select, { Props as ReactSelectProps, StylesConfig } from "react-select";
import cls from "classnames";
import SignInputError from "components/SignInputError";
import { SelectOption } from "../types";

const selectStyles: StylesConfig = {
  container: (styles) => ({
    ...styles,
    border: "1px solid #ecf2fe",
    borderRadius: "3px",
    paddingTop: "0px",
    paddingBottom: "0px",
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: "0px",
    paddingBottom: "0px",
  }),
  control: (styles) => ({
    ...styles,
    padding: "0",
    textAlign: "left",
    background: "#ecf2fe",
    border: "none",
    font: "16px Geomanist, sans-serif",
    fontWeight: "400",
    outline: "none",
    borderRadius: "3px",
    boxShadow: "none",
    ":hover": {
      border: "none",
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    paddingLeft: "15px",
    color: "#000",
    fontSize: "16px",
  }),
  placeholder: (styles) => ({
    ...styles,
    paddingLeft: "15px",
    color: "#5d6d82",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    cursor: "pointer",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    visibility: "hidden",
  }),
  input: (styles) => ({
    ...styles,
    color: "transparent",
    cursor: "pointer",
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: "4px",
    fontSize: "16px",
  }),
  option: (styles) => ({
    ...styles,
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "24px",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
  }),
};

export default function SignUpSelect({
  options,
  placeholder,
  onChange,
  errorMessage,
  onBlur,
  labelText,
  name,
  defaultValue,
  value,
}: Omit<ReactSelectProps, "options"> & {
  options: SelectOption[];
  errorMessage?: string;
  labelText?: string;
}) {
  const currentValue = !value
    ? undefined
    : options?.find((item) => item.value === value);
  return (
    <div className="signup-input-group">
      {labelText && name && (
        <label className="input-caption" htmlFor={name}>
          {labelText}
        </label>
      )}
      <div className="form-group floater">
        <Select
          className={cls({
            "parsley-error": !!errorMessage,
          })}
          isSearchable={false}
          blurInputOnSelect={false}
          options={options}
          onChange={onChange}
          onBlur={onBlur}
          styles={selectStyles}
          placeholder={placeholder}
          menuPlacement="auto"
          inputId={name}
          value={currentValue}
          defaultValue={defaultValue}
        />
        <SignInputError errorMessage={errorMessage} />
      </div>
    </div>
  );
}

import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  InputLabel,
  TextField,
  FormHelperText,
} from "@mui/material";

import EmailSampleUploader from "../components/EmailSampleUploader";
import { ISponsoredEmail } from "types";

const fields: {
  name: "list_size" | "user_open_total" | "user_click_total";
  label: string;
  description: string;
}[] = [
  {
    name: "list_size",
    label: "Number of Subscribers",
    description:
      "The number of active subscribers to your newsletter. It should not include bounces or people who have unsubscribed.",
  },
  {
    name: "user_open_total",
    label: "Total Unique Opens Per Email",
    description:
      "This is the number of subscribers who open each newsletter. It should be the unique count and shouldn't be a percentage. Use an average from your most recent sends.",
  },
  {
    name: "user_click_total",
    label: "Total Unique Clicks Per Email",
    description:
      "This is the number of subscribers who click on any link per newsletter. It should be the unique count and shouldn't be a percentage. Use an average from your most recent sends.",
  },
];

type Props = {
  siteSlug?: string;
  values: ISponsoredEmail;
  touched: { [key: string]: boolean };
  errors: { [key: string]: string };
  setFieldValue: (field: string, value: string) => void;
  handleChange: (e: React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
};

export default function SubscribersAndOpens({
  siteSlug,
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
}: Props) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseFloat(event.target.value) < 0) {
      setFieldValue(event.target.name, "0");
    } else {
      handleChange(event);
    }
  };
  return (
    <Card>
      <CardHeader
        title="Subscribers & Opens"
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent>
        <Grid container spacing={4} flexDirection="column">
          {fields.map((field) => (
            <Grid key={field.name} item>
              <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
              <TextField
                id={field.name}
                name={field.name}
                type="number"
                value={values[field.name]}
                onChange={onChange}
                onBlur={handleBlur}
                error={touched[field.name] && Boolean(errors[field.name])}
                data-error-element={Boolean(errors[field.name])}
                helperText={
                  touched[field.name] && Boolean(errors[field.name])
                    ? errors[field.name]
                    : ""
                }
              />
              <FormHelperText>{field.description}</FormHelperText>
            </Grid>
          ))}
          <Grid item>
            <EmailSampleUploader
              previewUrl={values.example_url}
              siteSlug={siteSlug}
              id={values.placements[0]?.id}
              callbackHandleUpload={(exampleUrl) =>
                setFieldValue("example_url", exampleUrl)
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

import { useContext, useEffect, useRef, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { Configure, InstantSearch } from "react-instantsearch";
import "./styles.scss";
import Categories from "./components/Categories";
import Content from "./components/Content";
import { UserContext } from "context";
import Filters from "./components/Filters";
import Header from "./components/FavoritesHeader";
import type { UiState } from "instantsearch.js";
import { getFilter, MIN, MAX } from "./components/SponsorshipCostSlider";

const INDEX_NAME = `Site_${process.env.REACT_APP_APP_ENV}`;

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIASEARCH_APP_ID ?? "",
  process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC ?? ""
);

const getAllFilters = (
  filters: string,
  isFavorite: boolean | undefined,
  userId: number | null
) => {
  if (filters && isFavorite && userId) {
    return `(${filters}) AND favorites: ${userId}`;
  } else if (filters) {
    return filters;
  } else if (isFavorite && userId) {
    return `favorites: ${userId}`;
  }
};

export default function MarketPlace({ isFavorite }: { isFavorite?: boolean }) {
  const {
    userData: { uuid, ro_id },
  } = useContext(UserContext);

  useEffect(() => {
    document.body.classList.add("marketplace-body");
    return () => {
      document.body.classList.remove("marketplace-body");
    };
  }, []);

  const [priceValue, setPriceValue] = useState<number[]>([MIN, MAX]);
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as Element).matches(".ais-Pagination-link")) {
      containerRef.current!.scrollIntoView({ behavior: "smooth" });
    }
  };

  const ref = useRef<number[]>();
  ref.current = priceValue;

  const onSearchStateChange = ({
    setUiState,
    uiState,
  }: {
    setUiState: (state: UiState) => void;
    uiState: UiState;
  }) => {
    const { refinementList, configure } = uiState[INDEX_NAME];
    const currentFormats = refinementList?.["formats"] || [];
    const priceFilters = getFilter(
      priceValue[0],
      priceValue[1],
      currentFormats
    );
    const filters = getAllFilters(priceFilters, isFavorite, ro_id);

    const newState = {
      [INDEX_NAME]: {
        ...uiState[INDEX_NAME],
        configure: {
          ...configure,
          filters,
        },
      },
    };
    setUiState(newState);
  };

  return (
    <>
      <Categories />
      {uuid && ro_id && (
        <InstantSearch
          searchClient={searchClient}
          indexName={INDEX_NAME}
          insights={true}
          routing={{
            stateMapping: {
              stateToRoute: (uiState) => {
                const indexUiState = uiState[INDEX_NAME];
                return {
                  query: indexUiState.query,
                  page: indexUiState.page,
                  toggle: indexUiState.toggle,
                  refinementList: indexUiState.refinementList,
                  range: indexUiState.range,
                  price: ref.current,
                  sortBy: indexUiState.sortBy?.replace(`${INDEX_NAME}_`, ""),
                };
              },
              routeToState: (routeState) => {
                const currentFormats =
                  routeState.refinementList?.["formats"] || [];
                const priceRange = routeState.price?.map(Number) || [MIN, MAX];
                setPriceValue(priceRange);

                const priceFilters = getFilter(
                  priceRange[0],
                  priceRange[1],
                  currentFormats
                );
                const filters = getAllFilters(priceFilters, isFavorite, ro_id);

                return {
                  [INDEX_NAME]: {
                    query: routeState.query,
                    refinementList: routeState.refinementList,
                    toggle: routeState.toggle,
                    page: routeState.page,
                    range: routeState.range,
                    sortBy: routeState.sortBy
                      ? `${INDEX_NAME}_${routeState.sortBy}`
                      : undefined,
                    configure: {
                      filters,
                    },
                  },
                };
              },
            },
          }}
          onStateChange={onSearchStateChange}
        >
          {isFavorite && <Header />}
          <section className="catalog" ref={containerRef}>
            <Filters priceValue={priceValue} setPriceValue={setPriceValue} />
            <div className="content">
              <Configure
                hitsPerPage={12}
                distinct
                clickAnalytics
                enablePersonalization={
                  process.env.REACT_APP_APP_ENV === "production"
                }
                userToken={uuid}
                facets={["favorites"]}
              />
              <Content
                showHeader={!isFavorite}
                handlePageClick={handlePageClick}
              />
            </div>
          </section>
        </InstantSearch>
      )}
    </>
  );
}
